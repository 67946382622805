import axios from "@/axios"

const base = "/api/nursing/"

class NurserySettingsService {
	// diagnostics
	async getDiagnostic({ status_id }) {
		const { data } = await axios.get(`${base}diagnostic`, {
			params: { status_id },
		})
		return data
	}
	async insertDiagnostic({ name }) {
		const { data } = await axios.post(`${base}diagnostic`, { name })
		return data
	}
	async updateDiagnostic({ id, name, status_id }) {
		const { data } = await axios.put(`${base}diagnostic/${id}`, { name, status_id })
		return data
	}
	async deleteDiagnostic({ id }) {
		const { data } = await axios.delete(`${base}diagnostic/${id}`)
		return data
	}
	// treatments
	async getTreatments({ status_id }) {
		const { data } = await axios.get(`${base}treatment`, {
			params: { status_id },
		})
		return data
	}
	async insertTreatment({ name }) {
		const { data } = await axios.post(`${base}treatment`, { name })
		return data
	}
	async updateTreatment({ id, name, status_id }) {
		const { data } = await axios.put(`${base}treatment/${id}`, { name, status_id })
		return data
	}
	async deleteTreatment({ id }) {
		const { data } = await axios.delete(`${base}treatment/${id}`)
		return data
	}
}
export default new NurserySettingsService()
