<template>
	<b-modal centered :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<b-form-group label="Nombre">
				<b-form-input v-model="name" />
			</b-form-group>
			<b-form-group label="Estado" v-if="isEditing">
				<b-form-select
					v-model="status"
					:options="[
						{ value: 1, text: 'Activo' },
						{ value: 2, text: 'Inactivo' },
					]"
				/>
			</b-form-group>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="info" @click="edit" v-if="isEditing" :disabled="!!!name">Editar</b-button>
			<b-button variant="success" @click="create" v-else :disabled="!!!name">Guardar</b-button>
		</template>
	</b-modal>
</template>

<script>
import nurseryService from "@/services/nursery/settings.service"

export default {
	props: {
		show: Boolean,
		// {_a:string} para saber si se edita
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: null,
		name: null,
		status: null,
	}),
	computed: {
		isEditing() {
			return this.info._a == "edit"
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.name = null
			this.status = null
			this.$emit("closing")
		},
		setFields() {
			this.name = this.info.name
			this.status = this.info.status_id
		},
		async create() {
			const { isConfirmed } = await this.showConfirmSwal({})
			if (!isConfirmed) return
			this.isLoading = true
			await nurseryService.insertDiagnostic({ name: this.name })
			this.isLoading = false
			this.handleHidden()
			this.$emit("refresh")
		},
		async edit() {
			const { isConfirmed } = await this.showConfirmSwal({})
			if (!isConfirmed) return
			this.isLoading = true
			console.log(this.name,this.status)
			await nurseryService.updateDiagnostic({ id: this.info.id, name: this.name, status_id: this.status })
			this.isLoading = false
			this.handleHidden()
			this.$emit("refresh")
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				if (this.isEditing) {
					this.title = `Editando diagnostico: ${this.info.name}`
					this.setFields()
				} else {
					this.title = `Crear nuevo diagnostico`
				}
			}
		},
	},
}
</script>
